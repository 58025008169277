<template>
    <v-container>
        <v-row>
            <v-col cols="12" class="pb-0 mt-6">
                <h3 class="title ml-1 font-weight-bold">Create Amp</h3>
            </v-col>

            <v-col cols="12" class="pt-0">
                <stepper ref="stepper" :steps="steps" @change="onStepChange">
                    <!-- get started -->
                    <v-stepper-content
                        class="wizard__content--item"
                        step="templates"
                        data-step="templates"
                        data-title="Get Started"
                    >
                        <get-started
                            :categories="categories"
                            :companies="companies"
                            :templates="templates"
                            :sub-templates="subTemplates"
                            :selected-template="selectedTemplate"
                            @next="nextStep"
                            @companyChanged="onCompanyChanged"
                            @companySaved="onCompanySaved"
                        />
                    </v-stepper-content>

                    <!-- content -->
                    <v-stepper-content
                        v-for="(section, index) in selectedTemplate.sections"
                        :key="`content-${index}`"
                        class="wizard__content--item"
                        :step="`content-${index}`"
                        :data-step="`content-${index}`"
                        :data-title="
                            selectedTemplate.sections.length == 1
                                ? 'Content'
                                : `Content ${index + 1}`
                        "
                    >
                        <content-section
                            :section="section"
                            :context-suggestions="contextSuggestions"
                            @input="setFieldContexts"
                            @next="nextStep"
                            @prev="prevStep"
                        />
                    </v-stepper-content>

                    <!-- headlines -->
                    <v-stepper-content
                        class="wizard__content--item"
                        step="headlines"
                        data-step="headlines"
                        data-title="Headlines"
                    >
                        <headlines @next="nextStep" @prev="prevStep" />
                    </v-stepper-content>

                    <!-- media -->
                    <v-stepper-content
                        class="wizard__content--item"
                        step="media"
                        data-step="media"
                        data-title="Media"
                    >
                        <media
                            :submitting="submitting"
                            @next="submit"
                            @prev="prevStep"
                        />
                    </v-stepper-content>
                </stepper>
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { ProvideReactive, Watch } from '@/utils/decorators';
import { mapGetters } from 'vuex';

import { InfoFieldMixin, TemplateMixin } from '@/mixins';

import type { Category } from '@/types/Category';
import type { Company } from '@/types/Company';
import type { ServerResponse } from '@/types/ServerResponse';
import type { PrTemplate, PrTemplateEditor } from '@/types/PrTemplate';

import { convertToArray, scrollTop } from '@/utils/helpers';

import { Stepper, Step } from '@/components/Stepper';
import {
    GetStarted,
    ContentSection,
    Headlines,
    Media
} from './components/Steps';

@Component({
    components: {
        Stepper,
        GetStarted,
        ContentSection,
        Headlines,
        Media
    },
    computed: {
        ...mapGetters('user', ['isReseller'])
    }
})
export default class Create extends mixins(InfoFieldMixin, TemplateMixin) {
    isReseller!: boolean;

    $refs!: {
        stepper: InstanceType<typeof Stepper>;
    };

    @ProvideReactive('formData')
    form: Record<string, string | number> = {};

    @Watch('form.prTemplateId')
    onPrTemplateIdChange() {
        this.getTemplates();
    }

    @Watch('form.subTemplateId')
    onSubTemplateIdChange() {
        this.getTemplates(true);
    }

    steps: Step[] = [
        {
            title: 'Get Started',
            step: 'templates',
            editable: false,
            completed: false
        },
        {
            title: 'Content',
            step: 'content',
            editable: false,
            completed: false
        },
        {
            title: 'Headlines',
            step: 'headlines',
            editable: false,
            completed: false
        },
        {
            title: 'Media',
            step: 'media',
            editable: false,
            completed: false
        }
    ];

    currentStep: Step = this.steps[0];

    @Watch('currentStep')
    onCurrentStepChange(v: Step) {
        if (v.step.includes('content')) {
            this.$nextTick(function () {
                this.setFieldContexts();
            });
        }

        if (v.step === 'headlines') {
            this.form.headline = this.replaceContentAnswers(
                this.form.headline as string
            );
        }
    }

    categories: Category[] = [];

    companies: Pick<Company, 'id' | 'name'>[] = [];

    templates: PrTemplate[] = [];

    @Watch('selectedTemplate')
    onTemplateChange(v: PrTemplateEditor) {
        if (v) {
            this.$nextTick(function () {
                this.updateSteps();

                this.hideAllInfoAlerts();
            });
        }
    }

    subTemplates: PrTemplate[] = [];

    selectedTemplate: PrTemplateEditor = {
        headline: '',
        description: '',
        summary: '',
        sections: []
    };

    submitting = false;

    mounted() {
        this.getData().then(() => {
            this.$nextTick(() => {
                this.$set(this.form, 'prTemplateId', 100); // set 'No Template' as default template
            });
        });
    }

    getData() {
        return this.$http
            .get<
                ServerResponse<{
                    categories: Category[];
                    companies: Pick<Company, 'id' | 'name'>[];
                    prTemplates: PrTemplate[];
                    userId: number;
                }>
            >(this.$route.fullPath)
            .then(({ data }) => {
                this.categories = data.data.categories;

                this.companies = convertToArray(data.data.companies);

                this.templates = this.setupTemplates(data.data.prTemplates);

                this.form.user_id = data.data.userId;
            });
    }

    setupTemplates(templates: PrTemplate[]) {
        const noTemplateIndex = templates.map(t => t.id).indexOf(100); // 100 is the template id of 'No Template' in api

        if (noTemplateIndex > -1) {
            const noTemplate = { ...templates[noTemplateIndex] };

            templates.splice(noTemplateIndex, 1);

            return [noTemplate, ...templates];
        }

        return templates;
    }

    getTemplates(subTemplate = false) {
        let url = `/pr_templates/get_templates/${this.form.prTemplateId}`;

        if (subTemplate && this.form.subTemplateId) {
            url = url.concat(`/${this.form.subTemplateId}`);
        }

        this.$http
            .get<
                ServerResponse<{
                    selectedTemplate: PrTemplate;
                    subTemplates?: PrTemplate[];
                }>
            >(url)
            .then(({ data }) => {
                this.form.press_release_template =
                    data.data.selectedTemplate.id;

                if (data.data.selectedTemplate.editor) {
                    const editor: PrTemplateEditor = JSON.parse(
                        data.data.selectedTemplate.editor
                    );

                    this.form.headline = editor.headline;

                    this.form.summary = editor.summary;

                    this.selectedTemplate = editor;
                }

                this.contextSentences = this.setContextSentences(
                    data.data.selectedTemplate.pr_template_variations
                );

                this.subTemplates = data.data.subTemplates ?? [];
            });
    }

    updateSteps() {
        const sections = Array.from(
            document.getElementsByClassName('wizard__content--item')
        );

        const steps: Step[] = [];

        sections.forEach(section => {
            steps.push({
                step: section.getAttribute('data-step') || '',
                title: section.getAttribute('data-title') || '',
                editable: false,
                completed: false
            });
        });

        this.steps = steps;
    }

    nextStep() {
        this.$refs.stepper.nextStep();

        scrollTop();
    }

    prevStep() {
        this.$refs.stepper.prevStep();

        scrollTop();
    }

    submit() {
        let url = '/pr_templates/template_answers';

        if (this.isReseller && this.$route.query.user_id) {
            url = `${url}?user_id=${this.$route.query.user_id}`;
        }

        this.submitting = true;

        this.$http
            .post<
                ServerResponse<{
                    announcement_id: number;
                }>
            >(url, this.form)
            .then(({ data }) => {
                if (data.meta.success) {
                    this.$router
                        .push(
                            // don't define target module in the link, use routes.ts
                            `/announcements/edit/${data.data.announcement_id}`
                        )
                        .then(() => {
                            this.$store.dispatch(
                                'notification/success',
                                data.meta.message
                            );
                        });

                    return;
                }

                this.$store.dispatch('notification/error', data.meta.message);
            })
            .finally(() => {
                this.submitting = false;
            });
    }

    onCompanyChanged(company: Company) {
        this.$set(this.form, 'company_id', company.id);
    }

    onCompanySaved(company: Company) {
        if (company) {
            this.getData().then(() => {
                this.$set(this.form, 'company_id', company.id);
            });
        }
    }

    onStepChange(step: Step) {
        this.currentStep = step;
    }
}
</script>
