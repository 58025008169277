import { VContainer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{staticClass:"wizard__content--item-container",attrs:{"fluid":""}},[_c('a-form',{attrs:{"submit":_vm.submit}},[_c('stepper-row',{scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('h6',{staticClass:"text-h6 mb-8 d-md-none"},[_vm._v("Headlines")]),_c('ValidationProvider',{attrs:{"name":"Formal Headline","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c(VTextField,{attrs:{"color":"secondary","name":"headline","label":"Formal Headline","error-messages":errors,"filled":""},on:{"focus":function($event){return _vm.showInfoAndFit('headline')}},model:{value:(_vm.formData.headline),callback:function ($$v) {_vm.$set(_vm.formData, "headline", $$v)},expression:"formData.headline"}})]}}])})]},proxy:true},{key:"info",fn:function(){return [_c('a-alert',{staticClass:"d-none",class:{
                        'position--absolute': _vm.$vuetify.breakpoint.mdAndUp
                    },attrs:{"data-type":"toggleable-info","data-alert":"alert-headline","type":"info"}},[_c('p',{staticClass:"mb-4"},[_vm._v(" This is for news services, so should not include any \"sensationalist\" language. Just stick to the facts. ")]),_c('p',{staticClass:"mb-4"},[_vm._v(" The most common words you'll use in this headline to make sure it's considered newsworthy are \"released\", \"launched\", \"expanded\", \"expansion announced\". ")]),_c('p',{staticClass:"mb-4"},[_vm._v(" Never use special characters and words like \"conversions\" or \"review\" in this headline. ")]),_c('p',[_vm._v(" Words at the start of the headline get more weight than those at the end and are more likely to rank. ")])])]},proxy:true}])}),_c('stepper-row',{scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('ValidationProvider',{attrs:{"name":"Informal Headline","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c(VTextField,{attrs:{"color":"secondary","name":"informal_headline","error-messages":errors,"label":"Informal Headline","filled":""},on:{"focus":function($event){return _vm.showInfoAndFit('informal_headline')}},model:{value:(_vm.formData.informal_headline),callback:function ($$v) {_vm.$set(_vm.formData, "informal_headline", $$v)},expression:"formData.informal_headline"}})]}}])})]},proxy:true},{key:"info",fn:function(){return [_c('a-alert',{staticClass:"d-none",class:{
                        'position--absolute': _vm.$vuetify.breakpoint.mdAndUp
                    },attrs:{"data-type":"toggleable-info","data-alert":"alert-informal_headline","type":"info"}},[_c('p',{staticClass:"mb-4"},[_vm._v(" This will go out to sites with more relaxed editorial rules, so it's okay to be a bit more enthusiastic. You can also use any special characters you want. ")]),_c('p',[_vm._v(" These headlines still have great Google ranking potential, so you'll still want to choose your keywords carefully and place the most important ones closer to the start of the headline. ")])])]},proxy:true}])}),_c('stepper-row',{scopedSlots:_vm._u([{key:"input",fn:function(){return [_c(VTextField,{attrs:{"color":"secondary","name":"alternative_headline","label":"Alternative Headline","filled":""},on:{"focus":function($event){return _vm.showInfoAndFit('alternative_headline')}},model:{value:(_vm.formData.alternative_headline),callback:function ($$v) {_vm.$set(_vm.formData, "alternative_headline", $$v)},expression:"formData.alternative_headline"}})]},proxy:true},{key:"info",fn:function(){return [_c('a-alert',{staticClass:"d-none",class:{
                        'position--absolute': _vm.$vuetify.breakpoint.mdAndUp
                    },attrs:{"data-type":"toggleable-info","data-alert":"alert-alternative_headline","type":"info"}},[_c('p',[_vm._v(" An alternative take on your main headlines. This will be used less frequently so it's okay to do something a bit different here. ")])])]},proxy:true}])}),_c('stepper-actions',{on:{"prev":function($event){return _vm.$emit('prev')}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }