<template>
    <v-container class="wizard__content--item-container">
        <a-form :submit="submit">
            <!-- categories -->
            <stepper-row v-if="!companyControl.show">
                <template #input>
                    <v-row>
                        <v-col md="6">
                            <validation-provider
                                v-slot="{ errors }"
                                name="Category"
                                rules="required"
                            >
                                <v-select
                                    id="category_id"
                                    v-model="formData.category_id"
                                    class="mt-3"
                                    :items="categories"
                                    item-text="name"
                                    item-value="id"
                                    color="secondary"
                                    name="category_id"
                                    item-color="secondary"
                                    placeholder="Select a category"
                                    label="Category"
                                    filled
                                    :error-messages="errors[0]"
                                    :disabled="loading"
                                />
                            </validation-provider>
                        </v-col>
                    </v-row>
                </template>

                <template #info>
                    <v-skeleton-loader
                        v-if="companyControl.loading"
                        type="list-item-avatar-three-line"
                    />

                    <company-card
                        v-if="
                            !companyControl.loading &&
                            companyControl.selectedCompany
                        "
                        :company="companyControl.selectedCompany"
                        @click="companyControl.show = true"
                    />
                </template>
            </stepper-row>

            <!-- company editor -->
            <stepper-row v-if="companyControl.show">
                <template #input>
                    <company-editor
                        ref="companyEditor"
                        :company-id="selectedCompanyId"
                        :is-creating="companyControl.isCreating"
                        @cancelled="onCompanyCancelled"
                        @saved="onCompanySaved"
                    >
                        <template #loading>
                            <v-skeleton-loader loading type="article" />
                        </template>
                    </company-editor>
                </template>
            </stepper-row>

            <!-- companies -->
            <stepper-row v-if="!companyControl.show">
                <template #input>
                    <v-row>
                        <v-col md="6">
                            <validation-provider
                                v-slot="{ errors }"
                                name="Company"
                                rules="required"
                            >
                                <v-select
                                    v-model="formData.company_id"
                                    class="mt-3"
                                    :items="companies"
                                    label="Company"
                                    item-text="name"
                                    item-value="id"
                                    color="secondary"
                                    name="company_id"
                                    item-color="secondary"
                                    placeholder="Select a company"
                                    filled
                                    :error-messages="errors[0]"
                                    :disabled="loading"
                                />
                            </validation-provider>
                        </v-col>

                        <v-col md="6">
                            <v-btn
                                v-if="companyControl.selectedCompany"
                                text
                                class="mt-6"
                                :disabled="loading"
                                @click.prevent="companyControl.show = true"
                            >
                                Edit
                                <v-icon right small color="primary">
                                    pencil
                                </v-icon>
                            </v-btn>

                            <v-btn
                                text
                                class="mt-6"
                                :disabled="loading"
                                @click.prevent="addNewCompany"
                            >
                                Add
                                <v-icon right small color="primary">
                                    plus-circle
                                </v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </template>
            </stepper-row>

            <!-- templates -->
            <stepper-row v-if="!companyControl.show">
                <template #input>
                    <v-row>
                        <v-col md="6">
                            <validation-provider
                                v-slot="{ errors }"
                                name="Template"
                                rules="required"
                            >
                                <v-select
                                    v-model="formData.prTemplateId"
                                    class="mt-3"
                                    :items="templates"
                                    item-text="name"
                                    item-value="id"
                                    label="Template"
                                    color="secondary"
                                    name="pr_template_id"
                                    item-color="secondary"
                                    placeholder="Select a template"
                                    filled
                                    :error-messages="errors[0]"
                                    :disabled="loading"
                                />
                            </validation-provider>
                        </v-col>
                    </v-row>
                </template>

                <template #info>
                    <a-alert type="info">
                        <strong>Select your template type,</strong>
                        and you'll be guided through the rest of the setup
                        process. You'll see your template build in real time and
                        you can edit it in preview mode to apply the finishing
                        touches.
                    </a-alert>

                    <a-alert v-if="templateDescription" type="success">
                        {{ templateDescription }}
                    </a-alert>
                </template>
            </stepper-row>

            <!-- sub-templates -->
            <stepper-row v-if="subTemplates.length && !companyControl.show">
                <template #input>
                    <v-row>
                        <v-col md="6">
                            <validation-provider
                                v-slot="{ errors }"
                                name="Sub-Template"
                                rules="required"
                            >
                                <label
                                    for="pr_sub_template_id"
                                    class="v-label"
                                    :class="{
                                        'error--text': errors.length
                                    }"
                                >
                                    Sub-Template
                                </label>

                                <v-select
                                    v-model="formData.subTemplateId"
                                    class="mt-3"
                                    :items="subTemplates"
                                    item-text="name"
                                    item-value="id"
                                    color="secondary"
                                    name="pr_sub_template_id"
                                    item-color="secondary"
                                    placeholder="Select a sub template"
                                    filled
                                    :error-messages="errors[0]"
                                    :disabled="loading"
                                />
                            </validation-provider>
                        </v-col>
                    </v-row>
                </template>
            </stepper-row>

            <!-- action button -->
            <stepper-actions v-if="!companyControl.show" hide-back-button />
        </a-form>
    </v-container>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { InjectReactive, Watch } from '@/utils/decorators';

import type { ServerResponse } from '@/types/ServerResponse';
import type { PrTemplate, PrTemplateEditor } from '@/types/PrTemplate';
import type { Category } from '@/types/Category';
import type { Company, CompanyControl } from '@/types/Company';

import { AForm } from '@/components/AForm';
import { AAlert } from '@/components/AAlert';
import { CompanyCard } from '@/components/CompanyCard';
import { CompanyEditor } from '@/components/CompanyEditor';
import { StepperActions, StepperRow } from '@/components/Stepper';

const GetStartedProps = Vue.extend({
    props: {
        categories: {
            type: Array as PropType<Category[]>,
            default() {
                return [];
            }
        },
        companies: {
            type: Array as PropType<Pick<Company, 'id' | 'name'>[]>,
            default() {
                return [];
            }
        },
        selectedTemplate: {
            type: Object as PropType<PrTemplateEditor>,
            default() {
                return {} as PrTemplateEditor;
            }
        },
        templates: {
            type: Array as PropType<PrTemplate[]>,
            default() {
                return [];
            }
        },
        subTemplates: {
            type: Array as PropType<PrTemplate[]>,
            default() {
                return [];
            }
        }
    }
});

@Component({
    components: {
        AAlert,
        AForm,
        CompanyCard,
        CompanyEditor,
        StepperActions,
        StepperRow
    }
})
export default class GetStarted extends GetStartedProps {
    @InjectReactive({
        from: 'formData',
        default() {
            return {};
        }
    })
    formData!: Record<string, object>;

    @Watch('formData.company_id')
    onCompanyIdChange(v: number) {
        if (v) {
            this.companyControl.loading = true;

            this.$http
                .get<ServerResponse<{ company: Company }>>(
                    `/companies/view/${v}`
                )
                .then(({ data }) => {
                    this.$emit('companyChanged', data.data.company);

                    this.companyControl.selectedCompany = data.data.company;
                })
                .finally(() => {
                    this.companyControl.loading = false;
                });
        }
    }

    get loading() {
        return this.$store.state.loading.show;
    }

    companyControl: CompanyControl = {
        show: false,
        loading: false,
        isCreating: false,
        selectedCompany: null
    };

    get selectedCompanyId() {
        return !this.companyControl.isCreating &&
            this.companyControl.selectedCompany
            ? this.companyControl.selectedCompany.id
            : 0;
    }

    get templateDescription() {
        return this.selectedTemplate.description;
    }

    addNewCompany() {
        this.companyControl.isCreating = true;

        this.companyControl.show = true;
    }

    onCompanySaved({ data }: ServerResponse<{ company: Company }>) {
        if (data.company) {
            this.companyControl.selectedCompany = data.company;

            this.companyControl.isCreating = false;

            this.companyControl.show = false;

            this.$emit('companySaved', data.company);
        }
    }

    onCompanyCancelled() {
        this.companyControl.isCreating = false;

        this.companyControl.show = false;
    }

    submit() {
        this.$emit('next');
    }
}
</script>
