import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VRow,{staticClass:"wizard__content--item-wrapper"},[_c(VCol,{staticClass:"wizard__content--item-actions align-start",attrs:{"md":"8"}},[(!_vm.hideBackButton)?_c(VBtn,{attrs:{"x-large":"","disabled":_vm.disableButton,"block":_vm.$vuetify.breakpoint.xsOnly},on:{"click":function($event){$event.preventDefault();return _vm.$emit('prev')}}},[_vm._v(" Back ")]):_vm._e(),_c(VBtn,{staticClass:"mt-4 mt-sm-0",class:{
                'mt-4 col-12': !_vm.showNextArrowIcon && _vm.$vuetify.breakpoint.xs
            },attrs:{"type":"submit","x-large":"","color":"primary","disabled":_vm.disableButton}},[_vm._v(" "+_vm._s(_vm.nextButtonText)+" "),(_vm.showNextArrowIcon)?_c(VIcon,{attrs:{"right":"","color":"white"}},[_vm._v(" arrow-right ")]):_vm._e()],1),(_vm.$vuetify.breakpoint.smAndDown)?_c('div',{staticClass:"mt-10"},[_vm._t("info")],2):_vm._e()],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c(VCol,{staticClass:"wizard__content--item-info",attrs:{"md":"4"}},[_vm._t("info")],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }