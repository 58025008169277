<template>
    <stepper-row>
        <template #input>
            <!-- inputs -->
            <validation-provider
                v-if="field.type === 'text'"
                v-slot="{ errors }"
                :name="getTitleCase(field.name)"
                :rules="field.required ? 'required' : ''"
            >
                <v-text-field
                    :id="field.name"
                    :name="field.name"
                    :value="form[field.name]"
                    :placeholder="field.hint"
                    :error-messages="errors"
                    filled
                    data-type="template-field"
                    :label="field.label"
                    :hint="field.hint"
                    persistent-hint
                    @focus="showInfoAndFit(field.name)"
                    @input="$emit('input', $event)"
                />
            </validation-provider>

            <validation-provider
                v-if="field.type === 'url'"
                v-slot="{ errors }"
                :name="getTitleCase(field.name)"
                :rules="field.required ? 'required|url' : 'url'"
            >
                <v-text-field
                    :id="field.name"
                    type="url"
                    :label="field.label"
                    :value="form[field.name]"
                    :name="field.name"
                    :placeholder="field.hint"
                    :error-messages="errors"
                    filled
                    data-type="template-field"
                    :hint="field.hint"
                    persistent-hint
                    @focus="showInfoAndFit(field.name)"
                    @input="$emit('input', $event)"
                />
            </validation-provider>

            <validation-provider
                v-if="field.type === 'textarea'"
                v-slot="{ errors }"
                :name="getTitleCase(field.name)"
                :rules="field.required ? 'required' : ''"
            >
                <v-textarea
                    :id="field.name"
                    :name="field.name"
                    :label="field.label"
                    :value="form[field.name]"
                    :placeholder="field.hint"
                    :rows="field.rows ? field.rows : 4"
                    :error-messages="errors"
                    filled
                    data-type="template-field"
                    @focus="showInfoAndFit(field.name)"
                    @input="$emit('input', $event)"
                />
            </validation-provider>

            <validation-provider
                v-if="field.type === 'select'"
                v-slot="{ errors }"
                :name="getTitleCase(field.name)"
                :rules="field.required ? 'required' : ''"
            >
                <v-select
                    :id="field.name"
                    :name="field.name"
                    :value="form[field.name]"
                    :items="field.options || []"
                    item-text="text"
                    item-value="value"
                    :error-messages="errors"
                    color="secondary"
                    item-color="secondary"
                    filled
                    :hint="field.hint"
                    :persistent-hint="true"
                    data-type="template-field"
                    @focus="showInfo(field.name)"
                    @input="$emit('input', $event)"
                />
            </validation-provider>

            <validation-provider
                v-if="field.type === 'prContent'"
                v-slot="{ errors }"
                rules="required"
            >
                <content-editor
                    :class="{ 'has-error': errors.length }"
                    :value="form.pr_content"
                    name="pr_content"
                    placeholder="Enter the body of your amp here. This should be written in the style of a news article -- stick to the facts, don’t be overly promotional, and provide only one link to your website."
                    @input="$emit('input', $event)"
                />

                <span
                    v-if="errors.length"
                    class="error--text text-caption pl-3"
                >
                    This field is required
                </span>
            </validation-provider>

            <!-- context suggestions -->
            <a-alert
                v-if="contextSuggestion"
                no-icon
                data-type="suggestion-alert"
                :data-suggestion-for="field.name"
                class="mb-4 font-italic"
                type="info"
                :message="contextSuggestion + '...'"
            />
        </template>

        <template #info>
            <template v-if="field.type === 'prContent'">
                <content-editor-validations
                    :content="form.pr_content"
                    rules="wordCountBetween:300,1000|linkCount:html|nonFirstPerson"
                />
            </template>

            <template v-else>
                <a-alert
                    v-if="field && field.tip"
                    class="d-none"
                    :data-alert="'alert-' + field.name"
                    data-type="toggleable-info"
                    :message="field.tip"
                    type="info"
                />
            </template>
        </template>
    </stepper-row>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component, { mixins } from 'vue-class-component';

import type { PrTemplateEditorSectionField } from '@/types/PrTemplate';

import { InfoFieldMixin } from '@/mixins';

import { toTitleCase } from '@/utils/helpers';

import { AAlert } from '@/components/AAlert';

import {
    ContentEditor,
    ContentEditorValidations
} from '@/components/ContentEditor';

import { StepperRow } from '@/components/Stepper';

const SectionFieldRowProps = Vue.extend({
    props: {
        field: {
            type: Object as PropType<PrTemplateEditorSectionField>,
            required: true
        },
        contextSuggestion: {
            type: String,
            default() {
                return null;
            }
        },
        form: {
            type: Object,
            required: true,
            default() {
                return {};
            }
        }
    }
});

@Component({
    components: {
        ContentEditor,
        AAlert,
        ContentEditorValidations,
        StepperRow
    }
})
export default class SectionFieldRow extends mixins(
    InfoFieldMixin,
    SectionFieldRowProps
) {
    getTitleCase(name: string) {
        return toTitleCase(name);
    }
}
</script>
