<template>
    <div class="text-truncate">
        <v-tooltip bottom>
            <template #activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">{{ text }}</span>
            </template>
            <span>{{ tooltipText }}</span>
        </v-tooltip>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

const TextTruncatedProps = Vue.extend({
    name: 'TextTruncated',
    props: {
        text: {
            type: String,
            default() {
                return '';
            }
        },
        tooltip: {
            type: String,
            default() {
                return '';
            }
        }
    }
});

@Component
export default class TextTruncated extends TextTruncatedProps {
    get tooltipText() {
        return this.tooltip || this.text;
    }
}
</script>
