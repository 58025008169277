<template>
    <v-container fluid class="wizard__content--item-container">
        <a-form :submit="submit">
            <stepper-row>
                <template #input>
                    <h6
                        v-if="$vuetify.breakpoint.smAndDown"
                        class="text-h6 mb-4"
                    >
                        Media
                    </h6>

                    <media-preview-list
                        :media-resources="mediaResources"
                        :user-id="formData.user_id"
                        class="mb-4"
                        module-title="Amp"
                        @mediaFiles="onMediaFilesUpdate"
                    />

                    <validation-provider
                        v-slot="{ errors }"
                        name="Media Resources"
                        rules="required|imageMediaResourceLength:5"
                        :skip-if-empty="false"
                    >
                        <input v-model="mediaResources" type="hidden" />

                        <span
                            v-show="errors.length > 0"
                            class="v-messages v-messages__message error--text"
                        >
                            Please provide at least 5 images
                        </span>
                    </validation-provider>
                </template>

                <template #info>
                    <a-alert type="info">
                        To ensure maximum media distribution and for best
                        results, please upload at least 5 brand-related images
                        that are relevant to your amp.
                    </a-alert>
                </template>
            </stepper-row>

            <stepper-actions
                next-button-text="Generate Amp"
                :show-next-arrow-icon="false"
                @prev="$emit('prev')"
            />
        </a-form>
    </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { InjectReactive, ProvideReactive, Watch } from '@/utils/decorators';

import type { FormMediaResource } from '@/types/Media';

import { AAlert } from '@/components/AAlert';
import { AForm } from '@/components/AForm';
import { MediaPreviewList } from '@/components/Media';

import { StepperActions, StepperRow } from '@/components/Stepper';

const MediaProps = Vue.extend({
    props: {
        submitting: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: { MediaPreviewList, AAlert, AForm, StepperActions, StepperRow }
})
export default class Media extends MediaProps {
    @InjectReactive({
        from: 'formData',
        default() {
            return {};
        }
    })
    formData!: {
        user_id?: number;
        media_resources?: FormMediaResource[];
    };

    @ProvideReactive()
    get user_id() {
        return Number(this.$route.query.user_id) || 0;
    }

    mediaResources: FormMediaResource[] = [];

    @Watch('mediaResources')
    onMediaResourcesChange(mediaResources: FormMediaResource[]) {
        this.formData.media_resources = mediaResources;
    }

    mounted() {
        this.mediaResources = this.formData.media_resources
            ? this.formData.media_resources
            : [];
    }

    submit() {
        this.$emit('next');
    }

    onMediaFilesUpdate(resources: FormMediaResource[]) {
        this.mediaResources = resources;
    }
}
</script>
