<template>
    <v-container fluid class="wizard__content--item-container">
        <a-form :submit="submit">
            <stepper-row>
                <template #input>
                    <h6 class="text-h6 mb-8 d-md-none">Headlines</h6>

                    <ValidationProvider
                        v-slot="{ errors }"
                        name="Formal Headline"
                        rules="required"
                    >
                        <v-text-field
                            v-model="formData.headline"
                            color="secondary"
                            name="headline"
                            label="Formal Headline"
                            :error-messages="errors"
                            filled
                            @focus="showInfoAndFit('headline')"
                        />
                    </ValidationProvider>
                </template>

                <template #info>
                    <a-alert
                        class="d-none"
                        data-type="toggleable-info"
                        data-alert="alert-headline"
                        type="info"
                        :class="{
                            'position--absolute': $vuetify.breakpoint.mdAndUp
                        }"
                    >
                        <p class="mb-4">
                            This is for news services, so should not include any
                            "sensationalist" language. Just stick to the facts.
                        </p>

                        <p class="mb-4">
                            The most common words you'll use in this headline to
                            make sure it's considered newsworthy are "released",
                            "launched", "expanded", "expansion announced".
                        </p>

                        <p class="mb-4">
                            Never use special characters and words like
                            "conversions" or "review" in this headline.
                        </p>

                        <p>
                            Words at the start of the headline get more weight
                            than those at the end and are more likely to rank.
                        </p>
                    </a-alert>
                </template>
            </stepper-row>

            <stepper-row>
                <template #input>
                    <ValidationProvider
                        v-slot="{ errors }"
                        name="Informal Headline"
                        rules="required"
                    >
                        <v-text-field
                            v-model="formData.informal_headline"
                            color="secondary"
                            name="informal_headline"
                            :error-messages="errors"
                            label="Informal Headline"
                            filled
                            @focus="showInfoAndFit('informal_headline')"
                        />
                    </ValidationProvider>
                </template>

                <template #info>
                    <a-alert
                        class="d-none"
                        data-type="toggleable-info"
                        data-alert="alert-informal_headline"
                        type="info"
                        :class="{
                            'position--absolute': $vuetify.breakpoint.mdAndUp
                        }"
                    >
                        <p class="mb-4">
                            This will go out to sites with more relaxed
                            editorial rules, so it's okay to be a bit more
                            enthusiastic. You can also use any special
                            characters you want.
                        </p>

                        <p>
                            These headlines still have great Google ranking
                            potential, so you'll still want to choose your
                            keywords carefully and place the most important ones
                            closer to the start of the headline.
                        </p>
                    </a-alert>
                </template>
            </stepper-row>

            <stepper-row>
                <template #input>
                    <v-text-field
                        v-model="formData.alternative_headline"
                        color="secondary"
                        name="alternative_headline"
                        label="Alternative Headline"
                        filled
                        @focus="showInfoAndFit('alternative_headline')"
                    />
                </template>

                <template #info>
                    <a-alert
                        class="d-none"
                        data-type="toggleable-info"
                        data-alert="alert-alternative_headline"
                        type="info"
                        :class="{
                            'position--absolute': $vuetify.breakpoint.mdAndUp
                        }"
                    >
                        <p>
                            An alternative take on your main headlines. This
                            will be used less frequently so it's okay to do
                            something a bit different here.
                        </p>
                    </a-alert>
                </template>
            </stepper-row>

            <stepper-actions @prev="$emit('prev')" />
        </a-form>
    </v-container>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { InjectReactive } from '@/utils/decorators';

import { AForm } from '@/components/AForm';
import { AAlert } from '@/components/AAlert';

import { StepperActions, StepperRow } from '@/components/Stepper';

import { InfoFieldMixin } from '@/mixins';

@Component({
    components: { AForm, AAlert, StepperActions, StepperRow }
})
export default class Headlines extends mixins(InfoFieldMixin) {
    @InjectReactive({
        from: 'formData',
        default() {
            return {};
        }
    })
    formData!: Record<string, object>;

    submit() {
        this.$emit('next');
    }
}
</script>
