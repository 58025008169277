import { VCard } from 'vuetify/lib/components/VCard';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c(VCard,{staticClass:"main-background",attrs:{"outlined":!_vm.flat,"flat":_vm.flat,"elevation":hover ? 2 : 0},on:{"click":function($event){return _vm.$emit('click')}}},[_c(VListItem,{attrs:{"three-line":""}},[_c(VListItemAvatar,{staticClass:"white",attrs:{"rounded":"","size":"100"}},[(!_vm.logo)?_c(VIcon,{attrs:{"large":"","color":"grey lighten-2"}},[_vm._v(" image ")]):_c(VImg,{attrs:{"max-width":"100","max-height":"100","src":_vm.logo}})],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"text-4 mb-1 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.company.name)+" ")]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.company.contact_name)+" ")]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.company.full_address)+" ")])],1)],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }