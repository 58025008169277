import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VStepperContent } from 'vuetify/lib/components/VStepper';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c(VRow,[_c(VCol,{staticClass:"pb-0 mt-6",attrs:{"cols":"12"}},[_c('h3',{staticClass:"title ml-1 font-weight-bold"},[_vm._v("Create Amp")])]),_c(VCol,{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('stepper',{ref:"stepper",attrs:{"steps":_vm.steps},on:{"change":_vm.onStepChange}},[_c(VStepperContent,{staticClass:"wizard__content--item",attrs:{"step":"templates","data-step":"templates","data-title":"Get Started"}},[_c('get-started',{attrs:{"categories":_vm.categories,"companies":_vm.companies,"templates":_vm.templates,"sub-templates":_vm.subTemplates,"selected-template":_vm.selectedTemplate},on:{"next":_vm.nextStep,"companyChanged":_vm.onCompanyChanged,"companySaved":_vm.onCompanySaved}})],1),_vm._l((_vm.selectedTemplate.sections),function(section,index){return _c(VStepperContent,{key:`content-${index}`,staticClass:"wizard__content--item",attrs:{"step":`content-${index}`,"data-step":`content-${index}`,"data-title":_vm.selectedTemplate.sections.length == 1
                            ? 'Content'
                            : `Content ${index + 1}`}},[_c('content-section',{attrs:{"section":section,"context-suggestions":_vm.contextSuggestions},on:{"input":_vm.setFieldContexts,"next":_vm.nextStep,"prev":_vm.prevStep}})],1)}),_c(VStepperContent,{staticClass:"wizard__content--item",attrs:{"step":"headlines","data-step":"headlines","data-title":"Headlines"}},[_c('headlines',{on:{"next":_vm.nextStep,"prev":_vm.prevStep}})],1),_c(VStepperContent,{staticClass:"wizard__content--item",attrs:{"step":"media","data-step":"media","data-title":"Media"}},[_c('media',{attrs:{"submitting":_vm.submitting},on:{"next":_vm.submit,"prev":_vm.prevStep}})],1)],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }