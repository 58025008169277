<template>
    <v-container class="wizard__content--item-container">
        <a-form :submit="submit">
            <v-row class="wizard__content--item-wrapper">
                <v-col md="8" class="wizard__content--item-content">
                    <span class="text-h5 font-weight-bold">
                        {{ section.header }}
                    </span>
                </v-col>
            </v-row>

            <section-field-row
                v-for="(field, idx) in section.fields"
                :key="`section-field-${idx}`"
                v-model="formData[field.name]"
                :field="field"
                :context-suggestion="contextSuggestions[field.name]"
                :form="formData"
                @input="$emit('input', $event)"
            />

            <stepper-actions @prev="$emit('prev')" />
        </a-form>
    </v-container>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { InjectReactive } from '@/utils/decorators';

import type {
    PrTemplateContextSuggestion,
    PrTemplateEditorSection
} from '@/types/PrTemplate';

import { AForm } from '@/components/AForm';
import { StepperActions } from '@/components/Stepper';

import SectionFieldRow from '../SectionFieldRow.vue';

const ContentSectionProps = Vue.extend({
    props: {
        section: {
            type: Object as PropType<PrTemplateEditorSection>,
            default() {
                return {} as PrTemplateEditorSection;
            }
        },
        contextSuggestions: {
            type: Object as PropType<PrTemplateContextSuggestion>,
            default() {
                return {} as PrTemplateContextSuggestion;
            }
        }
    }
});

@Component({
    components: { AForm, SectionFieldRow, StepperActions }
})
export default class ContentSection extends ContentSectionProps {
    @InjectReactive({
        from: 'formData',
        default() {
            return {};
        }
    })
    formData!: Record<string, string>;

    submit() {
        this.$emit('next');
    }
}
</script>
