<template>
    <v-row class="wizard__content--item-wrapper">
        <v-col md="8" class="wizard__content--item-actions align-start">
            <v-btn
                v-if="!hideBackButton"
                x-large
                :disabled="disableButton"
                :block="$vuetify.breakpoint.xsOnly"
                @click.prevent="$emit('prev')"
            >
                Back
            </v-btn>
            <v-btn
                type="submit"
                x-large
                color="primary"
                class="mt-4 mt-sm-0"
                :disabled="disableButton"
                :class="{
                    'mt-4 col-12': !showNextArrowIcon && $vuetify.breakpoint.xs
                }"
            >
                {{ nextButtonText }}

                <v-icon v-if="showNextArrowIcon" right color="white">
                    arrow-right
                </v-icon>
            </v-btn>

            <div v-if="$vuetify.breakpoint.smAndDown" class="mt-10">
                <slot name="info"></slot>
            </div>
        </v-col>

        <v-col
            v-if="$vuetify.breakpoint.mdAndUp"
            md="4"
            class="wizard__content--item-info"
        >
            <slot name="info"></slot>
        </v-col>
    </v-row>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

const StepperActionsProps = Vue.extend({
    props: {
        hideBackButton: {
            type: Boolean,
            default() {
                return false;
            }
        },
        nextButtonText: {
            type: String,
            default() {
                return 'Next';
            }
        },
        showNextArrowIcon: {
            type: Boolean,
            default() {
                return true;
            }
        },
        disabled: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component
export default class StepperActions extends StepperActionsProps {
    get loading() {
        return this.$store.state.loading.show;
    }

    get disableButton() {
        return this.loading || this.disabled;
    }
}
</script>
