<template>
    <v-row class="wizard__content--item-wrapper">
        <v-col md="8" class="wizard__content--item-content">
            <slot name="input"></slot>
            <slot v-if="$vuetify.breakpoint.smAndDown" name="info"></slot>
        </v-col>

        <v-col
            v-if="$vuetify.breakpoint.mdAndUp"
            md="4"
            class="wizard__content--item-info"
        >
            <slot name="info"></slot>
        </v-col>
    </v-row>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class StepperRow extends Vue {}
</script>
