import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperHeader } from 'vuetify/lib/components/VStepper';
import { VStepperItems } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VStepper,{staticClass:"wizard__container",attrs:{"alt-labels":"","value":_vm.currentStep.step}},[_c(VRow,{staticClass:"wizard__wrapper"},[_c(VCol,{staticClass:"wizard__header--container px-0",attrs:{"md":"8"}},[_c(VStepperHeader,{staticClass:"wizard__header--wrapper"},[_vm._l((_vm.stepperSteps),function(step,index){return [_c(VStepperStep,{key:`${step.step}-step`,class:{
                            'v-stepper__step--editable': step.editable
                        },attrs:{"step":step.step,"complete-icon":"check","complete":step.completed,"edit-icon":"pencil"},on:{"click":function($event){$event.preventDefault();return _vm.handleStepClick(index)}}},[_vm._v(" "+_vm._s(step.title)+" ")]),(index + 1 < _vm.stepperSteps.length)?_c(VDivider,{key:`${step.step}-divider`}):_vm._e()]})],2)],1),_c(VCol,{staticClass:"wizard__content--container",attrs:{"cols":"12"}},[_c(VStepperItems,{staticClass:"wizard__content--wrapper"},[_vm._t("default")],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }