<template>
    <v-stepper alt-labels class="wizard__container" :value="currentStep.step">
        <v-row class="wizard__wrapper">
            <v-col md="8" class="wizard__header--container px-0">
                <v-stepper-header class="wizard__header--wrapper">
                    <template v-for="(step, index) in stepperSteps">
                        <v-stepper-step
                            :key="`${step.step}-step`"
                            :step="step.step"
                            complete-icon="check"
                            :complete="step.completed"
                            edit-icon="pencil"
                            :class="{
                                'v-stepper__step--editable': step.editable
                            }"
                            @click.prevent="handleStepClick(index)"
                        >
                            {{ step.title }}
                        </v-stepper-step>

                        <v-divider
                            v-if="index + 1 < stepperSteps.length"
                            :key="`${step.step}-divider`"
                        />
                    </template>
                </v-stepper-header>
            </v-col>

            <v-col cols="12" class="wizard__content--container">
                <v-stepper-items class="wizard__content--wrapper">
                    <slot></slot>
                </v-stepper-items>
            </v-col>
        </v-row>
    </v-stepper>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { Watch } from '@/utils/decorators';

import { Step } from '.';

const StepProps = Vue.extend({
    props: {
        steps: {
            type: Array as PropType<Step[]>,
            required: true
        },
        onClick: {
            type: Function,
            default() {
                return function () {
                    return null;
                };
            }
        }
    }
});

@Component
export default class Stepper extends StepProps {
    stepperSteps: Step[] = this.steps;

    @Watch('steps')
    onStepsChange(steps: Step[]) {
        this.stepperSteps = steps;
    }

    currentStep: Step = this.stepperSteps[0];

    @Watch('currentStep')
    onCurrentStepChange(v: Step) {
        this.$emit('change', v);
    }

    setCurrentStep(index: number) {
        this.currentStep = this.stepperSteps[index];

        this.currentStep.editable = true;
    }

    getCurrentStepIndex() {
        return this.stepperSteps
            .map(step => step.step)
            .indexOf(this.currentStep.step);
    }

    handleStepClick(index: number) {
        if (this.steps[index].editable) {
            this.setCurrentStep(index);

            this.steps.forEach((step, idx: number) => {
                step.completed = idx < index;
            });
        }
    }

    nextStep() {
        const index = this.getCurrentStepIndex();

        if (index > -1) {
            this.stepperSteps[index].completed = true;

            this.stepperSteps[index].editable = true;

            this.setCurrentStep(index + 1);
        }
    }

    prevStep() {
        const index = this.getCurrentStepIndex();

        if (index > -1) {
            this.stepperSteps[index].completed = false;

            if (this.stepperSteps[index - 1]) {
                this.stepperSteps[index - 1].completed = false;

                this.setCurrentStep(index - 1);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import './Stepper.scss';
</style>
