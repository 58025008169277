<template>
    <v-hover v-slot="{ hover }">
        <v-card
            :outlined="!flat"
            :flat="flat"
            :elevation="hover ? 2 : 0"
            class="main-background"
            @click="$emit('click')"
        >
            <v-list-item three-line>
                <v-list-item-avatar rounded size="100" class="white">
                    <v-icon v-if="!logo" large color="grey lighten-2">
                        image
                    </v-icon>
                    <v-img
                        v-else
                        max-width="100"
                        max-height="100"
                        :src="logo"
                    />
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title class="text-4 mb-1 font-weight-bold">
                        {{ company.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        {{ company.contact_name }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                        {{ company.full_address }}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-card>
    </v-hover>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import type { Company } from '@/types/Company';

const CompanyCardOptions = Vue.extend({
    name: 'CompanyCard',
    props: {
        company: {
            type: Object as PropType<Company>,
            default() {
                return {
                    contact_name: '',
                    name: '',
                    full_address: '',
                    media_resources: []
                };
            }
        },
        flat: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component
export default class CompanyCard extends CompanyCardOptions {
    get logo() {
        return this.company.media_resources[0]?.media_file.relative_filename;
    }
}
</script>
